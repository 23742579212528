<template>
  <div class="account-page">
    <div class="d-flex account-tab ml-6 mr-5 my-1 mt-3">
      <v-tabs
        v-model="activeTabIndex"
        :background-color="themeController.bannerColor"
        slider-color="primary"
        color="unset"
        optional
        show-arrows
      >
        <v-tab
          v-for="(t, index) in activeTabs"
          :key="index"
          :value="index"
        >
          <document-tab-header
            :tab="activeTabs[index]"
            :hide-icon="activeTabs[index].documentType === 'to-do' && hideIcon"
          />
        </v-tab>
      </v-tabs>
      <v-spacer />
      <history-menu
        ref="history-menu"
        :tab="accountInfoTab"
        is-for-account
      />
    </div>

    <div class="account-detail">
      <keep-alive>
        <component
          v-if="loaded && activeTab"
          :tab="activeTab"
          :is="activeTab.documentType"
          :key="getTabKey(activeTab)"
          @document-ready="setDocumentReady($event)"
          @document-comment="displayDocumentComment($event)"
          @document-reset="resetDocumentState($event)"
          @document-saved="setDocumentSaved($event)"
          @document-can-save="setDocumentCanSave($event)"
          @document-title-change="setDocumentTitle($event)"
          @document-changed="setDocumentChanged($event)"
          @hide-icon="setHideIcon($event)"
          v-keep-scroll-position
        />
      </keep-alive>
    </div>
    <v-dialog
      v-model="recentsController.displayPopUp[accountId]"
      max-width=290
      @click:outside="recentsController.closePopUp(accountId)"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text class="text-truncate">{{documentTitle}}</v-card-text>
        <v-card-text class="mt-n4">{{$t('t.DocumentNotSavedDiscardChanges')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="recentsController.proceedPendingTabChanges({ documentId: accountId })">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="recentsController.closePopUp(accountId)"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import globalActions from '@/mixins/global-actions'
import recentsController from '@/recentsController'
import tabController from '@/tabController'
import themeController from '@/themeController'
import accountStaticTabs from './account-static-tabs'

export default {
  mixins: [accountStaticTabs, globalActions],
  components: {
    // AccountInfos: () => import('../../documents/accounts-infos/account-infos'),
    // newDocumentMenu: () => import('./new-document-menu'),
    AccountContacts: () => import('../account-contacts/account-contact-infos'),
    AccountInfos: () => import('./infos/account-infos'),
    AccountSummary: () => import('./summary/summary'),
    AllocationAnnouncements: () => import('./active-task/allocation-announcement/allocation-announcements'),
    CalendarBasedReminders: () => import('./active-task/reminder/calendar-based-reminders'),
    CollaborationTasks: () => import('./active-task/collaboration-task/collaboration-tasks'),
    Disputes: () => import('./active-task/dispute/disputes'),
    Documents: () => import('./documents'),
    DocumentTabHeader: () => import('@/components/document-tab-header'),
    DunningReminders: () => import('./active-task/reminder/dunning-reminders'),
    GenericTask: () => import('./active-task/generic-task/generic-task'),
    GroupamaRequests: () => import('./active-task/groupama/groupama-requests'),
    Hierarchy: () => import('./hierarchy'),
    HistoryMenu: () => import('@/components/history-menu'),
    Invoices: () => import('./invoices/invoice'),
    MessagesInbox: () => import('./active-task/message/messages-inbox'),
    MessagesOutbox: () => import('./active-task/message/messages-outbox'),
    NewMessage: () => import('./active-task/message/new-message'),
    Phone: () => import('./active-task/phone/phone'),
    Promises: () => import('./active-task/promise/promises'),
    ToDo: () => import('./to-do/to-do'),
    Topics: () => import('../topics/topic-infos'),
    WorkItemTemplates: () => import('./active-task/work-item-template/work-item-templates')
  },
  created () {
    this.query()
    this.initManualWorkItemTabs()
    this.setModule()

    const disputeTab = tabController.disputesTab()
    const promisesTab = tabController.promisesTab()
    const collaborationTasksTab = tabController.collaborationTasksTab()
    const workItemTemplatesTab = tabController.workItemTemplatesTab()
    const newMessageTab = tabController.newMessageTab()
    //    const accountContactsTab = tabController.accountContactsTab()
    const topicsTab = tabController.topicsTab()

    this.addGlobalActions(
      Object.assign(newMessageTab, { callback: () => recentsController.addTab(tabController.create(newMessageTab)), permissions: ['AccountManualDunning'] }),
      Object.assign(disputeTab, { callback: () => recentsController.addTab(tabController.create(disputeTab)), permissions: ['AccountNewDispute'] }),
      Object.assign(promisesTab, { callback: () => recentsController.addTab(tabController.create(promisesTab)), permissions: ['AccountNewPromise'] }),
      Object.assign(collaborationTasksTab, { callback: () => recentsController.addTab(tabController.create(collaborationTasksTab)), permissions: ['AccountNewCollaborationTask'] }),
      Object.assign(workItemTemplatesTab, { callback: () => recentsController.addTab(tabController.create(workItemTemplatesTab)), permissions: ['AccountFollowUp'] }),
      //    Object.assign(accountContactsTab, { callback: () => recentsController.addTab(tabController.create(accountContactsTab)), permissions: ['AccountContacts'] }),
      Object.assign(tabController.accountContactsTab(), { callback: () => this.$router.push(`/account-contacts/new-from-account/${this.$route.params.id}`), permissions: ['AccountContacts'] }),

      Object.assign(topicsTab, { callback: () => recentsController.addTab(tabController.create(topicsTab, { links: [{ id: this.linkTarget, grantUsersOf: true }] })) })
    )
  },
  activated () {
    this.query()
  },
  watch: {
    account (a) {
      this.setRecentControllerScope(a.id)
    },
    'recentsController.current': {
      deep: true,
      immediate: true,
      async handler (v) {
        if (v?.documentId === await this.$waitFor(() => this.accountId)) {
          this.current = Object.assign({}, this.current, v)
        }
      }
    },
    'activeTab.documentId' () {
      this.loaded = false
      this.$nextTick(() => {
        this.loaded = true
      })
    }
  },
  computed: {
    activeTabs: {
      get () {
        if (!this.account) { return this.accountStaticTabs }
        const tabs = this.accountStaticTabs.concat(this.current?.tabs || [])

        return Vue.observable(tabs)
      }
    },
    linkTarget () {
      return { id: this.accountId, type: 'accounts' }
    },
    activeTab () {
      return this.activeTabs[this.activeTabIndex]
    },
    activeTabIndex: {
      get () {
        return this.current?.activeTab ?? 0
      },
      set (i) {
        if (typeof i !== 'undefined') {
          if (!recentsController?.current) {
            recentsController.current = Object.assign({}, this.current)
          }
          recentsController.current.activeTab = i
        }
      }
    },
    accountId () {
      return this.account?.id
    },
    documentTitle () {
      return recentsController.hold.oldTab?.title
    },
    hideIcon: {
      get () {
        return !this.hasWorkToDo
      },
      set (value) {
        this.hasWorkToDo = !value
      }
    }
  },
  data () {
    return {
      account: null,
      current: null,
      hasWorkToDo: false,
      loaded: true,
      recentsController: recentsController,
      themeController: themeController
    }
  },
  methods: {
    async initManualWorkItemTabs () {
      if (this.$store.getters.currentUserHasPermission('AccountWorkItems')) {
        const workItemTypes = (await this.$http().get('/core/v6/work-item-types')).data
        workItemTypes.sort((a, b) => {
          return a.indexOrder - b.indexOrder
        })
        workItemTypes.forEach(wt => {
          if (!wt.isHidden && wt.code !== 'email') {
            if (wt.code === 'phone') {
              const task = tabController.phoneTab()
              this.addGlobalActions(Object.assign(task, { typeId: wt.id, callback: () => recentsController.addTab(tabController.create(task)) }))
            } else {
              const task = tabController.genericTaskTab()
              this.addGlobalActions(Object.assign(task, { typeName: wt.name, typeId: wt.id, callback: () => recentsController.addTab(tabController.create(task)) }))
            }
          }
        })
      }
    },
    displayDocumentComment (data) {
      data.tab.showComment = data.value
    },
    getTabKey (t) {
      return t?.documentType
    },
    async setDocumentSaved (tab) {
      await this.query()
      tab.isNew = false
    },
    setDocumentReady (tab) {
      tab.isLoaded = true
    },
    resetDocumentState (tab) {
      tab.isLoaded = false
      tab.isPristine = true
    },
    setRecentControllerScope (accountId) {
      recentsController.scope = {
        documentType: 'accounts',
        documentId: accountId
      }
    },
    setDocumentCanSave (data) {
      data.tab.canSave = data.value
    },
    setHideIcon (data) {
      this.hideIcon = data.value
    },
    setDocumentTitle (data) {
      data.tab.title = data.value
    },
    setDocumentChanged (tab) {
      tab.isPristine = false
    },
    setModule () {
      let moduleData = { name: 'Account' }

      if (this.account) {
        moduleData = {
          componentName: 'account-header',
          data: this.account
        }

        this.setRecentControllerScope(this.account.id)
      }
      if (this.$route.params.id) {
        this.$store.commit('setModule', moduleData)
      }
    },
    async query () {
      console.log('query')
      const accountId = await this.$waitFor(() => this.$route.params.id)
      this.setRecentControllerScope(accountId)

      const e = await this.$http().get('/core/v6/accounts/' + accountId)
      this.account = e.data
      this.setModule()
      this.hideIcon = !(await this.$http().get('/core/v6/accounts/' + accountId + '/work-items', { params: { showAll: true } })).data.items.length
    }
  },
  props: {
    date: String,
    number: String
  }
}
</script>

<style lang="stylus" scoped>
.account-page
  display grid
  grid-template-areas 'tab' 'doc'
  grid-template-columns 100%
  grid-template-rows min-content 1fr
  flex-grow 1
  overflow hidden

.account-tab
  grid-area tab

.v-tabs
  max-width max-content

.account-page>.v-toolbar
  flex-grow 0

.account-detail
  display flex
  grid-area doc
  flex-grow 1
  overflow hidden
  flex-direction row

.account-detail>.v-sheet
  overflow auto
  flex-grow 1

#documents, #summary
  display flex
  flex-grow 1
  overflow auto

.selected
  border-bottom 3px solid #039BE5
</style>

<style lang="stylus">
.no-color
  color unset !important
</style>
